.wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.price {
  font-family: var(--font-regular);
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.toprow {
  padding-right: 2rem;
}