.root {
  max-width: 28.25rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  text-align: left;
}

.control {
  background: var(--dark-purple);
  color: #fff;

  border-radius: 1.75rem;
  cursor: pointer;
  padding: 1rem 1.75rem;

  font-size: 1.125rem;
  font-family: var(--font-medium);

  margin-left: 0.8125rem;
  margin-right: 0.8125rem;
}

.filled .control {
  color: var(--dark-purple);
  background: #fff;
}

.root[class~="is-open"] .control,
.control:hover {
  background: var(--dark-purple);
  color: #fff;
}

.disabled .control {
  color: #c7c6cd;
  cursor: not-allowed;
  background: #fff;
  transition: 0.45s color;
}

.menu {
  border: 0;
  top: initial;
  bottom: calc(100% + 0.5rem);
  max-height: 28rem;

  width: calc(100% - 1.625rem);
  margin: auto;
  left: 0;
  right: 0;
}

.rootSearch .menu {
  bottom: calc(100% + 4rem);
}

/* option */
.menu > div {
  color: var(--text-color);
  border-bottom: 1px solid #ebe7e2;
  padding: 1rem;
  text-align: left;
  transition: 0.05s background-color;
}

.menu > div:last-child {
  border-bottom: 0;
}

.menu > div[class~="is-selected"],
.menu > div:hover {
  background-color: #e2ddd5;
}

.arrowClosed,
.arrowOpen {
  width: 0.75rem;
  height: 0.75rem;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  right: 1.75rem;
  top: 1.35rem;
}

.arrowClosed {
  background-image: url("../images/dropdown-arrow-closed-white.svg");
}

.disabled .arrowClosed {
  background-image: url("../images/dropdown-arrow-closed-purple.svg");
}

.root[class~="is-open"] .arrowClosed,
.root:not(.disabled):not(.summary) .control:hover .arrowClosed {
  background-image: url("../images/dropdown-arrow-closed-white.svg");
}

.filled .arrowClosed {
  background-image: url("../images/dropdown-arrow-closed-purple.svg");
}

.arrowOpen {
  background-image: url("../images/dropdown-arrow-open-purple.svg");
}

.root[class~="is-open"] .arrowOpen,
.control:hover .arrowOpen,
.filled .arrowOpen {
  background-image: url("../images/dropdown-arrow-open-white.svg");
}

.bottomGap {
  margin-bottom: 1rem;
}

.summaryFade {
  flex-grow: 1;
}

.summary {
  max-width: none;
  margin-top: 0;
}

.summary .control {
  color: var(--dark-purple);
  background: #fff;
  border-radius: 0;
  border: 1px solid #c7c6cd;
  margin: 0;
  border-radius: 0.375rem;
  padding: 0.75rem 1rem;
  height: 3rem;
  font-family: var(--font-regular);
  font-size: 1rem;
}

.summary .arrowClosed,
.summary .arrowOpen {
  right: 1rem;
  top: 1.125rem;
}

.searchRoot {
  max-width: 28.25rem;

  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -4rem;
  left: 0;
  right: 0;
  z-index: 1100;
}

.root[class~="is-open"] + .searchRoot {
  display: block;
  opacity: 1;
  visibility: visible;
  margin: auto;
}

.searchInputWrapper {
  background: #fff;
  padding: 0.5rem;
  display: flex;

  width: calc(100% - 2.625rem);
  margin: auto;
  text-align: left;
}

.searchInput {
  border-radius: 0.125rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-family: var(--font-medium);

  position: relative;
  box-sizing: border-box;

  border: 0;

  background: #f2f2f2;
  color: #afafaf;

  flex: 1;

  background-image: url("images/search.svg");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.25rem;
}

.menu > div[class*="noresults"] {
  font-size: 0;
}

.menu > div[class*="noresults"]:hover {
  background: #fff;
}

.menu > div[class*="noresults"]:after {
  content: var(--no-options);
  font-size: 1.125rem;
}

@media only screen and (max-width: 1281px) {
  .control,
  .searchInput {
    font-size: 1rem;
  }

  .menu > div[class*="noresults"]:after {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .control {
    margin-left: 0;
    margin-right: 0;
  }

  .menu {
    width: 100%;
  }

  .searchInputWrapper {
    width: auto;
  }
}
