.default {
  margin-bottom: 1rem;
}

.default form {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.input {
  align-items: center;
  justify-content: center;

  padding: 0 1rem;
  margin: 0;
  height: 3.5rem;

  font-size: 1.125rem;
  line-height: 1.5rem;

  border-radius: 0.375rem;
  font-family: var(--font-medium);

  min-width: 17.875rem;
  background-color: #fff;

  color: var(--dark-purple);
  border: 1px solid #C7C6CD;

}

.submit {
  align-items: center;
  justify-content: center;

  border-radius: 0 1.5rem 1.5rem 0;
  font-family: var(--font-medium);
  height: 3rem;
  min-width: 4.875rem;

  padding: 0 1.5rem;
  background-color: var(--light-beige);

  color: var(--dark-purple);
  box-sizing: border-box;
  border: 1px solid var(--dark-purple);
  transition: 0.25s all;
}

.disabled .submit {
  cursor: not-allowed;
  opacity: 0.6;
}

@media only screen and (max-width: 1281px) {
  .default {
    margin-bottom: 0rem;
  }

  .default form {
    flex-direction: column;
  }

  .input {
    font-size: 1rem;
    height: calc(3rem - 2px);
    margin-top: 0.5rem;
    min-width: 13.875rem;
  }
}

@media only screen and (max-width: 600px) {
  .input {
    width: calc(100% - 2rem);
    min-width: 0;
  }
}
