.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 1.75rem;
  font-family: var(--font-medium);
  border: 0;

  min-width: 13.875rem;
  padding: 0 3rem;
  margin: 0 0.8125rem;
  height: 3.5rem;

  background-color: var(--button-background-color);
  color: var(--button-text-color);

  cursor: pointer;

  font-size: 1.125rem;
  line-height: 1.5rem;

  transition: ease-in-out 0.25s all;
}

.button > span {
  display: flex;
  align-items: center;
}

.dark {
  color: var(--dark-purple);
  background-color: var(--light-beige);
}

.uppercase {
  text-transform: uppercase;
}

.disabled {
  background-color: var(--button-disabled-background-color);
  color: var(--button-text-color);
  cursor: not-allowed;
}

.secondary {
  background-color: var(--current-bg-color, var(--light-beige));
  height: calc(3.5rem - 2px); /* 2px being borders */
  min-width: calc(13.875rem - 2px);
  color: var(--dark-purple);
  border: 1px solid var(--dark-purple);
}

.icon {
  min-width: 0;
  padding: 0;
  height: 2.5rem;
  width: 2.5rem;
  background-position: center;
  background-size: 1.375rem;
  background-repeat: no-repeat;
  background-color: var(--light-beige);
  font-size: 0 !important;
  margin: 0;
}

.icon.share-link {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%231e1a34' d='M13.723 18.654l-3.61 3.609c-2.316 2.315-6.063 2.315-8.378 0-1.12-1.118-1.735-2.606-1.735-4.188 0-1.582.615-3.07 1.734-4.189l4.866-4.865c2.355-2.355 6.114-2.262 8.377 0 .453.453.81.973 1.089 1.527l-1.593 1.592c-.18-.613-.5-1.189-.964-1.652-1.448-1.448-3.93-1.51-5.439-.001l-.001.002-4.867 4.865c-1.5 1.499-1.5 3.941 0 5.44 1.517 1.517 3.958 1.488 5.442 0l2.425-2.424c.993.284 1.791.335 2.654.284zm.161-16.918l-3.574 3.576c.847-.05 1.655 0 2.653.283l2.393-2.389c1.498-1.502 3.94-1.5 5.44-.001 1.517 1.518 1.486 3.959 0 5.442l-4.831 4.831-.003.002c-1.438 1.437-3.886 1.552-5.439-.002-.473-.474-.785-1.042-.956-1.643l-.084.068-1.517 1.515c.28.556.635 1.075 1.088 1.528 2.245 2.245 6.004 2.374 8.378 0l4.832-4.831c2.314-2.316 2.316-6.062-.001-8.377-2.317-2.321-6.067-2.313-8.379-.002z'/%3E%3C/svg%3E");
}

.icon.whatsapp {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='800' height='800' viewBox='0 0 308 308'%3E%3Cpath fill='%232cb742' d='M227.904 176.981c-.6-.288-23.054-11.345-27.044-12.781-1.629-.585-3.374-1.156-5.23-1.156-3.032 0-5.579 1.511-7.563 4.479-2.243 3.334-9.033 11.271-11.131 13.642-.274.313-.648.687-.872.687-.201 0-3.676-1.431-4.728-1.888-24.087-10.463-42.37-35.624-44.877-39.867-.358-.61-.373-.887-.376-.887.088-.323.898-1.135 1.316-1.554 1.223-1.21 2.548-2.805 3.83-4.348a140.77 140.77 0 0 1 1.812-2.153c1.86-2.164 2.688-3.844 3.648-5.79l.503-1.011c2.344-4.657.342-8.587-.305-9.856-.531-1.062-10.012-23.944-11.02-26.348-2.424-5.801-5.627-8.502-10.078-8.502-.413 0 0 0-1.732.073-2.109.089-13.594 1.601-18.672 4.802C90 87.918 80.89 98.74 80.89 117.772c0 17.129 10.87 33.302 15.537 39.453.116.155.329.47.638.922 17.873 26.102 40.154 45.446 62.741 54.469 21.745 8.686 32.042 9.69 37.896 9.69h.001c2.46 0 4.429-.193 6.166-.364l1.102-.105c7.512-.666 24.02-9.22 27.775-19.655 2.958-8.219 3.738-17.199 1.77-20.458-1.348-2.216-3.671-3.331-6.612-4.743z'/%3E%3Cpath fill='%232cb742' d='M156.734 0C73.318 0 5.454 67.354 5.454 150.143c0 26.777 7.166 52.988 20.741 75.928L.212 302.716a3.998 3.998 0 0 0 4.999 5.096l79.92-25.396c21.87 11.685 46.588 17.853 71.604 17.853C240.143 300.27 308 232.923 308 150.143 308 67.354 240.143 0 156.734 0zm0 268.994c-23.539 0-46.338-6.797-65.936-19.657a3.996 3.996 0 0 0-3.406-.467l-40.035 12.726 12.924-38.129a4.002 4.002 0 0 0-.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 0-65.543 53.754-118.867 119.826-118.867 66.064 0 119.812 53.324 119.812 118.867.001 65.535-53.746 118.851-119.811 118.851z'/%3E%3C/svg%3E");
}

.buttons.fullWidth .button,
.fullWidth {
  width: 100%;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

.topGap {
  margin-top: 2rem;
}

.header {
  font-size: 1rem;
  height: 2.5rem;
  box-sizing: border-box;
  background-color: var(--beige);
  color: #fff;
}

.buttons {
  display: flex;
  justify-content: center;
}

.buttons.stack {
  flex-direction: column;
  max-width: 28.25rem;
  margin: auto;
  gap: 1rem;
}

.buttons.insideModal {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: 1rem;
}

.buttons.fullWidth {
  flex-direction: column;
  max-width: none;
}

.buttons.insideModal > a {
  display: contents;
}

.buttons.insideModal .button {
  margin: 0;
  width: auto;
}

.tiny {
  font-size: 1rem;
  border-radius: 1.5rem;
  height: 3rem;
  min-width: 0;
  margin-right: 0;
  margin-top: 0.5rem;
}

.dark.secondary {
  background-color: var(--dark-purple);
  color: var(--light-beige);
  border: 1px solid var(--light-beige);
}

@media only screen and (max-width: 1281px) {
  .button {
    font-size: 1rem;
    border-radius: 1.5rem;
    height: 3rem;
    margin-top: 0.5rem;
    padding: 0 1rem;
  }

  .secondary {
    height: calc(3rem - 2px); /* 2px being borders */
  }

  .header {
    height: 2.5rem;
    margin-top: 0;
  }

  .fullWidth {
    width: calc(100% - 2rem);
  }

  .topGap {
    margin-top: 1rem;
  }

  .icon {
    height: 2.5rem;
    width: 2.5rem;
    background-size: 1.375rem;
  }

  .buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .buttons.fullWidth {
    width: 100%;
  }

  .buttons.stack {
    gap: 0;
  }
}

@media only screen and (max-width: 600px) {
  .button {
    margin-left: 0;
    margin-right: 0;
    width: calc(100% - 2rem);
  }

  .secondary {
    width: calc(100% - 2rem - 2px); /* 2px being borders */
  }

  .header {
    height: 2.25rem;
    margin-top: 0;
    min-width: 0;
    padding: 0 0.75rem;
    line-height: 1rem;
    width: auto;
  }

  .cover {
    width: auto;
  }

  .icon {
    height: 2.5rem;
    width: 2.5rem;
    background-size: 1.375rem;
  }

  .buttons.stack {
    max-width: none;
  }

  .buttons.insideModal {
    grid-auto-flow: row;
    gap: 0.5rem;
  }
}
