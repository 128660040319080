.banner {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
  text-align: center;

  background-color: var(--light-beige);

  border-top: 1px solid var(--dark-purple);
}

.container {
  max-width: 80%;
  margin: auto;
}
