.title {
  margin: 2rem 0 0.5rem;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-flow: row wrap;
  margin-bottom: 2rem;
}

.row > div {
  flex: 33%;
  max-width: calc(33% - 1rem);
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

@media only screen and (max-width: 1281px) {
  .row {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 0;
  }
  .row > div {
    flex: none;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}
