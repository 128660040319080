@font-face {
  font-family: "TTFors-Regular";
  src: url("./fonts/TTFors-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "TTFors-Medium";
  src: url("./fonts/TTFors-Medium.woff2") format("woff2");
}

:root {
  font-size: 16px;

  --white: #fff;
  --dark-purple: #1e1a34;
  --dark-purple--opacity-60: rgba(30, 26, 52, 0.6);
  --beige: #c5bbaa;
  --light-beige: #ebe7e2;
  --extra-light-beige: #f7f5f3;
  --apricot: #f3cfb3;

  --gray-beige: #d6d2cf;
  --dark-beige: #d6cfc3;
  --light-purple: #332d47;

  --text-color: var(--dark-purple);
  --background-color: var(--light-beige);

  --button-text-color: #fff;
  --button-background-color: var(--dark-purple);
  --button-disabled-background-color: #c2bdbe;

  --progress-bar-color: var(--dark-purple);
  --progress-bar-background-color: var(--gray-beige);

  --font-medium: "TTFors-Medium", Arial, sans-serif;
  --font-regular: "TTFors-Regular", Arial, sans-serif;

  --bp-desktop: 1281px;
}

.content-wrapper {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 6.25rem);
  justify-content: flex-start;
  max-width: 67.5rem;
  margin: auto;
  position: relative;
}

.content-wrapper-logo {
  height: 10.625rem;
  pointer-events: none;
  margin: 1rem;
}

.App-content {
  margin: 1.5rem 2rem 2rem;
}

a {
  color: inherit;
  text-decoration: none;
}

@media only screen and (max-width: 1441px) {
  .App-content {
    margin: 1.5rem 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .App-content {
    margin: 0;
  }
}
