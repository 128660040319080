.content {
  color: var(--dark-purple);
  margin: 1.5rem 2rem 2rem;
}

.title {
  color: var(--dark-purple);
  font-family: var(--font-medium);
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;

  display: inline-block;
}

.tac a {
  text-decoration: underline;
}

.tac input {
  margin-right: 0.5rem;
}

.tac label {
  cursor: pointer;
}

@media only screen and (max-width: 1281px) {
  .title {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
}
