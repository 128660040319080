.wrapper {
  max-width: 80%;
  margin: 4.25rem auto;
  background-color: #fff;
  border-radius: 0.25rem;
}

.header img {
  width: 100%;
  height: 17.5rem;
  object-fit: cover;
  border-radius: 0.25rem 0.25rem 0 0;
}

.contentWrapper {
  max-width: 60%;
  padding: 4.75rem;
  margin: auto;
  text-align: center;
}

.content {
  padding-bottom: 1.75rem;
}

.content p {
  margin: 0 auto 1.75rem;
}

@media only screen and (max-width: 1281px) {
  .wrapper {
    max-width: calc(100% - 2rem);
    margin: 1rem auto;
    background-color: #fff;
  }

  .contentWrapper {
    max-width: none;
    padding: 0 1rem 2rem;
  }
}
