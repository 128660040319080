.checkbox {
  border: solid 1px #c7c6ce;
  border-radius: 0.375rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3.5rem;
  padding: 0 1rem;
  transition: 0.15s all ease-in-out;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  background-color: #fff;
}

.filled {
  border: solid 1px var(--dark-purple);
  background-color: #ededef;
}

.checkmark {
  background-image: url("images/checkmark-white.svg");
  background-color: #fff;
  background-size: 0.5625rem 0.5625rem;
  background-repeat: no-repeat;
  background-position: center;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 0.1875rem;
  margin-right: 0.5rem;
  border: solid 1px #c7c6ce;
  box-sizing: border-box;
  transition: 0.15s all ease-in-out;
}

.filled .checkmark {
  background-color: var(--dark-purple);
  border: 0;
}

.radio {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  margin-right: 0.75rem;
  border: solid 1px #c7c6ce;
  box-sizing: border-box;
  transition: 0.15s all ease-in-out;
  padding: 3px;
  background-clip: content-box;
}

.filled .radio {
  background-color: var(--dark-purple);
  border: solid 1px var(--dark-purple);
}

@media only screen and (max-width: 1281px) {
  .checkbox {
    height: 3rem;
  }
}
