.bottom {
  margin-top: auto;
  padding-top: 1rem;
}

.stackOnDesktop {
  margin-top: 2.5rem;
}

.floating {
  position: fixed;
  bottom: 1rem;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.placeholderForFloater {
  height: 2rem;
}

@media only screen and (max-width: 600px) {
  .stackOnDesktop {
    margin-top: auto;
  }
}
