.card {
  background-color: #fff;
  /* color: var(--button-text-color); */

  cursor: pointer;

  font-size: 1rem;
  line-height: 1.5rem;

  display: flex;
  flex-direction: column;

  width: 13.5625rem;

  text-align: left;
  padding: 1rem;
  margin-right: 1rem;

  border-radius: 0.25rem;

  margin: auto;
}

.title {
  font-family: var(--font-medium);
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin-bottom: 0.5rem;
}

.price {
  margin-bottom: 1rem;

  font-size: 1.125rem;
}

.image {
  max-height: 9.25rem;
}

.image img {
  object-fit: contain;
}

.showmore {
  font-family: var(--font-medium);
  font-size: 1rem;
  text-align: center;
  margin-top: auto;
  padding-top: 1rem;
}

.card:hover .showmore {
  text-decoration: underline;
}

@media only screen and (max-width: 568px) {
  div[class="alice-carousel"] .card {
    margin: 0;
  }
}
