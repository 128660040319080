.icon img {
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.icon, .placeholder {
  height: 13.625rem;
  margin: 2rem auto 1.75rem;
  width: 7.375rem;
}

.icon {
  padding-top: 5rem;
}

@media only screen and (max-width: 1281px) {
  .icon, .placeholder {
    height: 10.625rem;
    margin: 2rem auto 1.75rem;
    width: 7.375rem;
  }

  .icon {
    padding-top: 0;
  }
}
