body {
  margin: 0;
  font-family: var(--font-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
}

.h1 {
  font-family: var(--font-medium);
  font-size: 2.125rem;
  line-height: 2rem;
}

.h2 {
  font-family: var(--font-medium);
  font-size: 1.375rem;
  line-height: 1.875rem;
}

.h3 {
  font-family: var(--font-medium);
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.h4 {
  font-family: var(--font-medium);
  font-size: 1.125rem;
  line-height: 1.625rem;
}

@media only screen and (max-width: 1281px) {
  .h1 {
    font-family: var(--font-medium);
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .h2 {
    font-family: var(--font-medium);
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .h3 {
    font-family: var(--font-medium);
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .h4 {
    font-family: var(--font-medium);
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
