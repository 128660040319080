.title,
.block {
  --current-bg-color: #fff;

  background: var(--current-bg-color);
  text-align: left;
  padding: 1rem 2rem;
}

.block {
  margin: 1rem auto;
}

.title + .block {
  margin-top: 1px;
}

.subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-medium);
}

.subtitle .price {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid var(--light-beige);
}

.edit {
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
}

.edit .pen {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.375rem;
}

.edit.isEditing {
  opacity: 0.4;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.row:has(.error) {
  background-color: var(--apricot);
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.confirmCancel.disabled {
  display: none;
}

.block:has(.error) .confirmCancel.disabled {
  display: flex;
}

.block:has(.error) .confirmCancel {
  display: none;
}

.key {
  width: 30%;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
}

.value,
.editingValue {
  background-color: var(--extra-light-beige);
  border: 1px solid var(--extra-light-beige);
  border-radius: 0.375rem;
  padding: 0 1rem;
  height: calc(3rem - 2px);
  flex-grow: 1;
  display: flex;
  align-items: center;
  min-width: 30%;
  justify-content: space-between;
}

.editingValue {
  color: var(--dark-purple);
  background: #fff;
  border: 1px solid #c7c6cd;
  cursor: pointer;
}

input.editingValue {
  line-height: 1.5rem;
  font-family: var(--font-regular);
  font-size: 1rem;
}

.value + .value,
.editingValue + .editingValue {
  margin-left: 0.5rem;
}

.subblocktitle {
  text-decoration: underline;
  font-family: var(--font-medium);
  height: 3rem;
  display: flex;
  align-items: center;
}

.total {
  text-align: left;
  margin-bottom: 1rem;
}

.total .toprow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partprice {
}

.confirmCancel {
  display: flex;
  justify-content: flex-end;
}

.shareSummary {
  background: white;
  margin: 1rem 0px;
  padding: 2rem;
  display: flex;
  align-items: center;
}

.shareSummaryLeft {
  width: 30%;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.shareSummaryRight {
  width: 70%;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .title {
    padding: 1rem;
  }

  .block {
    padding: 0 1rem 1rem;
  }

  .row {
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .row:last-of-type {
    margin-bottom: 0;
  }

  .key {
    width: auto;
  }

  .value {
    padding: 0 0.5rem;
  }

  .value + .value,
  .editingValue + .editingValue {
    margin-left: 0;
  }

  .shareSummary {
    flex-direction: column;
    gap: 1rem;
  }

  .shareSummaryLeft {
    width: 100%;
  }

  .shareSummaryRight {
    width: 100%;
  }

  .confirmCancel {
    gap: 0.5rem;
  }
}
