.default {
  border-radius: 1rem;
  padding: 1.375rem 1.625rem;

  background-color: var(--white);
  color: var(--dark-purple);

  font-size: 1.125rem;
  line-height: 1.625rem;

  width: 22.625rem;
  max-width: 65%;
 
  margin-bottom: 1rem;

  text-align: left;

  white-space: pre-wrap;
}

.userHolder {
  display: flex;
  justify-content: flex-end;
}

.user {
  background-color: var(--dark-beige);
  color: var(--dark-purple);
}

@media only screen and (max-width: 1281px) {
  .default {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
