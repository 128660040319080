.navi {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0 0;
  font-family: var(--font-medium);
}

.prev {
  background-image: url("images/modal-prev.svg");
  background-size: 0.5rem 0.875rem;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 1.25rem;
  cursor: pointer;
}

.next {
  background-image: url("images/modal-next.svg");
  background-size: 0.5rem 0.875rem;
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 1.25rem;
  cursor: pointer;
}

@media only screen and (max-width: 1281px) {
  .navi {
    margin: 1rem 0 0.5rem;
  }
}