.bodyText {
  font-size: 1.125rem;
  line-height: 1.625rem;
  max-width: 40rem;
  margin: auto;
  margin-bottom: 1rem;
}

.large {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.small {
  font-size: 1rem;
  line-height: 1.5rem;
}

.fullWidth {
  max-width: none;
}

@media only screen and (max-width: 1281px) {
  .bodyText {
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 90%;
  }

  .large {
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .small {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  .fullWidth {
    max-width: none;
  }
}
