.cardrow {
  position: relative;
  display: flex;
  align-items: center;
}

.classic {
  display: flex;
  justify-content: center;
  margin: auto;
}

.prev,
.next {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -3rem;
}

.prev {
  left: -3rem;
  background-image: url("images/modal-prev.svg");
}

.next {
  right: -2rem;
  background-image: url("images/modal-next.svg");
}

.cardrow
  ul[class="alice-carousel__dots"]
  li[class="alice-carousel__dots-item"] {
  background-color: var(--dark-purple);
  opacity: 0.2;
}

.cardrow ul[class="alice-carousel__dots"] li[class~="__active"] {
  background-color: var(--dark-purple) !important;
  opacity: 1;
}

[data-amount="2"] li[class*="alice-carousel__stage-item"]:first-child > div {
  margin-right: 1rem;
}

[data-amount="2"] li[class*="alice-carousel__stage-item"]:last-child > div {
  margin-left: 1rem;
}

[data-amount="2"] .prev,
[data-amount="2"] .next {
  display: none;
}

@media only screen and (max-width: 1281px) {
  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }

  .cardrow div[class="alice-carousel"] {
    width: calc(100% - 5rem);
  }
}

@media only screen and (max-width: 568px) {
  .prev,
  .next {
    display: none;
  }

  [data-amount="2"] li[class*="alice-carousel__stage-item"]:first-child > div {
    margin-right: auto;
  }

  [data-amount="2"] li[class*="alice-carousel__stage-item"]:last-child > div {
    margin-left: auto;
  }

  .cardrow {
    overflow: hidden;
    margin: 0 -1rem;
  }

  .cardrow div[class="alice-carousel"] {
    width: 16rem;
    margin: 0 1rem;
  }

  .cardrow div[class="alice-carousel__wrapper"] {
    overflow-y: visible;
    overflow-x: visible;
  }
}
