.header {
  height: 2rem;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: calc(100% - 2rem);
  top: 0;
  background-color: var(--background-color);
  z-index: 1000;
}

.logo {
  height: 100%;
  pointer-events: none;
  transition: all 1s;
}

.infobutton {
  display: initial;
  opacity: 1;
}

.infomodal {
  text-align: left;
  color: var(--dark-purple);
}

.infofooter {
  background: var(--light-beige);
  margin: 2rem -2rem -2rem -2rem;
  padding: 1rem 2rem 2rem 2rem;

  border-radius: 0 0 0.25rem 0.25rem;
}

.progress {
  background-color: var(--progress-bar-background-color);
  position: fixed;
  width: 100%;
  top: 4rem;
  z-index: 1000;
}

.indicator {
  background-color: var(--progress-bar-color);
  height: 0.25rem;
}

@media only screen and (max-width: 1281px) {
  .infofooter {
    background: var(--light-beige);
    margin: 1rem -1rem -1rem -1rem;
    padding: 1rem;
  }
}
