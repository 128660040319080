.cover {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: auto;
	max-width: 67.5rem;
	flex-grow: 1;
}

.textcolor {
	color: var(--light-beige);
}

.body {
	color: var(--light-beige);
	font-size: 1.125rem;
	line-height: 1.625rem;
	max-width: 40rem;
	margin: auto;
}