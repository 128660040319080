.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-purple);
  z-index: 1;
  opacity: 0.6;
}

.content {
  --modal-padding: 2rem;

  width: 90%;
  max-width: 35.75rem;
  background: white;
  z-index: 3;
  position: relative;
  padding: var(--modal-padding);
  border-radius: 0.25rem;
}

.close {
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;
  font-size: 2rem;
  cursor: pointer;
  transition: 0.15s opacity;
  color: var(--dark-purple);
}

.close:hover {
  opacity: 1;
}

@media only screen and (max-width: 1281px) {
  .modal {
    display: block;
  }
  .background {
    position: fixed;
  }
  .content {
    width: calc(90% - 1rem);
    margin: 1rem auto;
    --modal-padding: 1rem;

  }
}
