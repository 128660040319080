.image {
  height: 9.25rem;
  object-fit: contain;
}

.full {
  max-width: 100%;
  object-fit: contain;
  max-height: 250px;
  height: auto;
}

.medium {
  max-width: 100%;
  object-fit: contain;
  max-height: 250px;
  height: auto;
}

.full {
  max-width: 100%;
  object-fit: contain;
  height: auto;
}

.placeholder {
  height: 9.25rem;
  background-color: var(--gray-beige);
}

.topGap {
  margin-top: 2rem;
}

.zoomableWrapper {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 250px;
}

.zoomIcon {
  background-image: url("../images/zoom-in.svg");
  background-size: cover;
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.zoomableModalWrapper {
  position: fixed;
  inset: 0;
  z-index: 999;
  background-color: var(--dark-purple--opacity-60);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.zoomableModalWrapper > img {
  max-width: 100%;
  height: auto;
}

.zoomableModalContent {
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
}

.zoomableModalContent > img {
  max-width: 90vw;
  max-height: 90vh;
  width: auto;
  height: auto;
}

@media only screen and (max-width: 1281px) {
  .tiny {
    height: 6.25rem;
  }

  .topGap {
    margin-top: 1rem;
  }
}
